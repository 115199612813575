<template>
  <el-container class="home-page">
    <el-header>
      <home-header></home-header>
    </el-header>
    <el-container class="container">
      <el-aside width="120px" class="el-menu-vertical-demo">
        <home-menu></home-menu>
      </el-aside>
      <el-main class="center">
        <div class="tabs-switch-page" v-if="!false">
          <div class="tag-page">
            <el-tag size="medium" v-for="(tab, index) in tabList" :key="tab.bizId" @close="handleClose(tab, index)" @click="changeMenu(tab)" :closable="tab.bizId !== '主页'" :style="activePath === tab.bizId ? 'color: #fff' : ''" :effect="activePath === tab.bizId ? 'dark' : 'plain'">
              {{tab.name}}
            </el-tag>
            <el-tag v-if="tabList.length > 1" @close="handleAllClose" @click="handleAllClose" closable effect="dark" size="medium" class="tag-page-close">全部关闭</el-tag>
          </div>
        </div>
        <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path != '/home'">
          <el-breadcrumb-item :to="{path: '/home'}">首页</el-breadcrumb-item>
          <el-breadcrumb-item v-for="item in this.$router.currentRoute.matched" :key="item.path">{{item.name}}</el-breadcrumb-item>
        </el-breadcrumb>
        <home-generalize v-if="this.$router.currentRoute.path == '/home'"></home-generalize>
        <router-view class="homeRouterView" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import homeHeader from '../components/home/Header'
import homeMenu from '../components/home/Menu'
import homeGeneralize from '../components/home/Generalize'
import { mapState } from 'vuex'
export default {
  name: 'Home',
  data() {
    return {
      user: JSON.parse(window.sessionStorage.getItem('user'))
    }
  },
  components: {
    homeHeader,
    homeMenu,
    homeGeneralize
  },
  methods: {
    // // 查询用户权限接口
    // async onBtnClick () {
    //   const roles = JSON.parse(window.localStorage.getItem('roles'))
    //   if (roles && roles.length > 0) {
    //     const data = {
    //       roleCode: roles[0].code
    //     }
    //     const res = await this.getRequest('permission/query', data)
    //     if (res.status !== 200) {
    //       return false
    //     }
    //     const records = res.pageModel.records
    //     if (records && records.length > 0) {
    //       window.localStorage.setItem('authority', JSON.stringify(records[0].pcPermission))
    //     }
    //   }
    // },
    // 全部关闭
    handleAllClose() {
      this.$router.replace({ path: '/home' })
      this.$store.commit('clearAllClose')
    },
    fnCommand(command) {
      if (command === 'logout') {
        this.$confirm('你确定要退出吗？( ╯□╰ )，是否继续？', '退出提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.postRequest('/sys/user/logout')
            window.sessionStorage.removeItem('tokenStr')
            window.sessionStorage.removeItem('user')
            this.$store.commit('initRoutes', [])
            this.$router.replace('/')
          })
          .catch(() => {
            this.$message.info('已取消操作！')
          })
      }
    },
    // 关闭tab标签
    handleClose(tab, index) {
      // 历史选中菜单
      let oldActivePath = this.$store.state.activePath
      // 历史已选中菜单列表
      const oldTabList = this.$store.state.tabList
      // 计算标签个数
      const length = oldTabList.length - 1
      // 删除tabList中的该对象
      for (let i = 0; i < oldTabList.length; i++) {
        const item = oldTabList[i]
        if (item.bizId === tab.bizId) {
          oldTabList.splice(i, 1)
        }
      }
      // 删除keepAlive缓存
      this.$store.commit('removeKeepAliveCache', tab.name)
      // 如果关闭的标签不是当前路由的话，就不跳转
      if (tab.bizId !== oldActivePath) {
        return
      }
      // 如果length为1，必然只剩下首页标签，此时关闭后，更新到首页
      if (length === 1) {
        // 同时存储菜单
        this.$store.commit('closeTab', {
          activePath: '主页',
          tabList: oldTabList
        })
        // tab页向左跳转
        this.$router.push({ path: oldTabList[index - 1].path })
        // 不再向下执行
        return
      }
      // 关闭的标签是最右边的话，往左边跳转一个
      if (index === length) {
        // 同时更新路径
        oldActivePath = oldTabList[index - 1].bizId
        // 同时存储菜单
        this.$store.commit('closeTab', {
          activePath: oldActivePath,
          tabList: oldTabList
        })
        // tab页向左跳转
        this.$router.push({ path: oldTabList[index - 1].path })
      } else {
        // 同时更新路径
        oldActivePath = oldTabList[index].bizId
        // 同时存储菜单
        this.$store.commit('closeTab', {
          activePath: oldActivePath,
          tabList: oldTabList
        })
        // tab页向右跳转
        this.$router.push({ path: oldTabList[index].path })
      }
    },
    // 点击标签跳转路由
    changeMenu(item) {
      // 历史选中菜单
      const oldActivePath = this.$store.state.activePath
      // 首先判断点击的是否是自己，如果是自己则return
      if (oldActivePath === item.bizId) {
        return
      }
      // 不是自己，存储菜单
      this.$store.commit('changeMenu', item.bizId)
      // 页面跳转
      this.$router.push({ path: item.path })
    }
  },
  computed: {
    routes() {
      return this.$store.state.routes
    },
    ...mapState({
      // 从 state 中的到的计算属性
      activePath: state => state.activePath, // 已选中菜单
      tabList: state => state.tabList, // tags菜单列表
      catch_components: state => state.catch_components // keepalive缓存
    })
  },
  mounted() {
    // this.onBtnClick()
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/css/public';
.home-page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: #f7f7f7;
  .el-header {
    padding: 0;
  }
  .container {
    width: 100%;
    //height: 90%; // 动态获取
    height: calc(100% - 200px);
  }
  .el-aside {
    color: #333;
    background: #fff;
    overflow: hidden;
    //border-top: 1px solid #f7f7f7;
    border-right: 1px solid #f7f7f7;
  }
  .center {
    color: #333;
    overflow: hidden;
    box-sizing: border-box;
    padding: 10px 10px;
    margin: 0;
    height: 100%;
    width: 100%;
    position: relative;
  }
  // tab页
  .tabs-switch-page {
    width: 100%;
    height: 40px;
    background-color: #fff;
    margin-bottom: 10px;
    overflow: hidden;
    .tag-page {
      height: 100%;
      width: calc(100% - 100px);
      overflow: auto;
      display: flex;
      align-items: center;
      .el-tag {
        cursor: pointer;
        margin-left: 10px;
        border-radius: 2px;
        font-size: 12px;
        color: #1890ff;
        border-color: #1890ff;
      }
      .tag-page-close {
        color: #fff;
        position: absolute;
        right: 20px;
      }
    }
  }
  .breadcrumb {
    background: #ffffff;
    padding: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    width: 100%;
  }
  .homeRouterView {
    height: calc(100% - 80px);
    width: 100%;
    margin-top: 10px;
    overflow: hidden;
    background: #fff;
    border-radius: 5px;
    box-sizing: border-box;
  }
}
</style>
