import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '../store'
import { Message } from 'element-ui'
import router from '../router'

NProgress.configure({ showSpinner: false })
// 请求拦截器
axios.interceptors.request.use(
  success => {
    if (success.headers.showLoading) {
      NProgress.start()
      delete success.headers.showLoading
    }
    success.params = success.params || {}
    if (window.localStorage.getItem('applicationName')) {
      if (!success.params.applicationName) {
        success.params.applicationName = window.localStorage.getItem('applicationName')
      }
      if (!success.params.hotelId) {
        success.params.hotelId = window.localStorage.getItem('hotelBizId')
      }
    }
    //   if (!success.params._v) {
    //     success.params._v = String(Math.random()).slice(0, 10) // eslint-disable-line
    //     if (success.url.includes('admin/account/pageable') || success.url.includes('admin/account/recharge/pageable')) {
    //       console.log('-')
    //     } else {
    //       if (window.localStorage.getItem('applicationName')) {
    //         success.params.applicationName = window.localStorage.getItem('applicationName')
    //       }
    //     }
    //     if (store.state.hotellist && store.state.hotellist.length > 0) {
    //       if (!success.url.includes('ogz/user/pageable') &&
    //         !success.url.includes('admin/account/pageable') &&
    //         !success.url.includes('ogz/role/pageable') &&
    //         !success.url.includes('admin/account/add/command') &&
    //         !success.url.includes('ogz/group/pageable') &&
    //         !success.url.includes('menu/tree') &&
    //         !success.url.includes('users/user/query')
    //       ) {
    //         success.params.orgBizId = store.state.hotellist[0].bizId
    //       }
    //     }
    //   }

    if (typeof window === 'undefined') {
      success.url = success.url.replace(/^https/, 'http')
    }
    if (window.localStorage.getItem('token') != undefined && window.localStorage.getItem('token') != null) {
      const token = window.localStorage.getItem('token').toString()
      success.headers.accessToken = token
      if (success.url.indexOf('attachment/upload') > 0 || success.url.indexOf('version2/update') > 0) {
        // upload上传图片绕过axios拦截器 --单独自己设置content-type
      } else {
        if (success.headers['Content-Type'] == undefined) {
          success.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
        }
      }
    }
    return success
  },
  error => {
    // console.log('error', error)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  success => {
    //   console.log('success:', success)
    //   console.log('success.status:', success.status)
    NProgress.done()
    // 业务逻辑错误
    if (success != undefined && success.status === 200) {
      // console.log('success.data.code:', success.data.code)
      if (success.data.code === 500 || success.data.code === 401 || success.data.code === 403) {
        if (success.data.msg !== '登录失败，其他内部错误') {
          Message.error(success.data.msg)
        }
        //   Message.error({ message: success.data.msg })
        // 重置vuex中的数据
        store.commit('clearVUEX')
        window.localStorage.clear()
        router.replace('/')
      }
      if (success.data.msg == 'token不正确') {
        store.commit('clearVUEX')
        window.localStorage.clear()
        router.replace('/')
      }
      return success.data
    }
    // console.log('相应拦截:' + JSON.stringify(success))
    return success.data
  },
  error => {
    NProgress.done()
    // console.log('error.response', error)
  }
)
export const wss = true

// 本地地址
export const baseUrl = 'http://192.168.0.121:7710/lxy/'
// 文件域名
export const imageUrl = 'http://127.0.0.1:9000/image/'
// soket接口
export const socketUrl = 'http://192.168.0.121:7710/socket/ws'
// 文本框
export const tinymceCDN = '/tinymce-all-in-one-master/tinymce.min.js'

// // 文件上传接口  公共
export const uploadFile = baseUrl + 'sys/upload/file'
// 传送json格式的post请求
export const postRequest = (url, params) => {
  return axios({
    method: 'post',
    url: `${baseUrl}${url}`,
    data: params
  })
}

export const postRequestImage = (url, params) => {
  return axios({
    method: 'post',
    url: `${baseUrl}${url}`,
    data: params,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 传送json格式的get请求
export const getRequest = (url, params = {}) => {
  return axios({
    method: 'get',
    url: `${baseUrl}${url}`,
    headers: {
      showLoading: true
    },
    params: params
  })
}

// 传送json格式的get请求
export const getRequestImg = (url, params = {}) => {
  return axios({
    method: 'get',
    url: `${baseUrl}${url}`,
    headers: {
      showLoading: true
    },
    // responseType: 'blob',
    // responseType: 'arraybuffer',
    params: params
  })
}

// 传送json格式的put请求
export const putRequest = (url, params) => {
  return axios({
    method: 'put',
    url: `${baseUrl}${url}`,
    data: params
  })
}
// 传送json格式的delete请求
export const deleteRequest = (url, params) => {
  return axios({
    method: 'delete',
    url: `${baseUrl}${url}`,
    data: params
  })
}

export const post = (url, params) => {
  return axios({
    method: 'post',
    url: `${baseUrl}${url}`,
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      accessToken: window.localStorage.getItem('token') == undefined ? '' : window.localStorage.getItem('token') == null ? '' : window.localStorage.getItem('token').toString()
    }
  })
}

// -- 旧的已不用 -----------------------------------------

// // 传送短信json格式的post请求 multipart/form-data
// export const getRequestShort = (url, params) => {
//   return axios({
//     method: 'get',
//     url: `${baseUrlShort}${url}`,
//     params: params
//   })
// }
// export const postRequestShort = (url, params) => {
//   return axios({
//     method: 'post',
//     url: `${baseUrlShort}${url}`,
//     headers: {
//       'Access-Control-Allow-Origin': '*'
//     },
//     data: params
//   })
// }

// // 传送json格式的post请求
// export const postRequestAdmin = (url, params) => {
//   return axios({
//     method: 'post',
//     url: `${baseUrlAdmin}${url}`,
//     data: params
//   })
// }
// // 传送Adminjson格式的get请求
// export const getRequestAdmin = (url, params = {}) => {
//   return axios({
//     method: 'get',
//     url: `${baseUrlAdmin}${url}`,
//     headers: {
//       showLoading: true
//     },
//     params: params
//   })
// }
