<template>
  <div class="generalize-page">
    <div class="box" v-if="type.includes('banquet')">
      <el-card :class="['real-time-data', 'real-time-data'+index]" v-for="(item, index) in list" :key="item.id">
        <h4>{{ item.name }}</h4>
        <ul class="list">
          <li class="item-list" v-for="itemi in item.children" :key="itemi.id">
            <h5>{{ itemi.name }}</h5>
            <h3>
              {{itemi.text}}
              <span style="font-size: 16px; color: #bf0000" v-if="itemi.work">{{itemi.work}}</span>
            </h3>
          </li>
        </ul>
      </el-card>
      <el-card class="common-functions">
        <!--      <h4>常用功能</h4>-->
      </el-card>
    </div>
    <div style="width: 100%; height: 100%; background: #fff" v-else>
      <img width="100%" height="100%" src="../../assets/images/menpai-bg.png">
    </div>
  </div>
</template>

<script>
const dateTime = new Date()
export default {
  name: 'Generalize',
  data() {
    return {
      type: [],
      list: [],
      date: {
        year: '',
        month: '',
        day: ''
      }
    }
  },
  methods: {
    // 初始化日期，选择日期
    initDate() {
      this.date = {
        year: dateTime.getFullYear().toString(),
        month: (dateTime.getMonth() + 1).toString().padStart(2, '0'),
        day: dateTime
          .getDate()
          .toString()
          .padStart(2, '0')
        // day: 13
      }
    },
    async init() {
      const data = {
        hotelBizId: this.$store.state.hotellist[0].bizId,
        ...this.date
      }
      const res = await this.post('statistics/banquet/intime/data', data)
      //   console.log('查询统计结果：' + JSON.stringify(res))
      if (res.status !== 200) {
        return false
      }
      const records = res.pageModel.records[0]
      this.records = records
      this.list = [
        {
          name: '宴会实时数据',
          children: [
            {
              id: 1,
              name: '今日已定订单',
              text: records && records.todayReserves ? records.todayReserves : 0
            },
            {
              id: 2,
              name: '今日预留订单',
              text: records && records.todayRetains ? records.todayRetains : 0
            },
            {
              id: 3,
              name: '今日预收款总金额',
              text:
                records && records.todayTotalPreAmount
                  ? (records.todayTotalPreAmount / 10000).toFixed(2)
                  : 0,
              work: '/万元'
            },
            {
              id: 4,
              name: '昨日已定订单',
              text:
                records && records.preDayReserves ? records.preDayReserves : 0
            },
            {
              id: 5,
              name: '昨日预留订单',
              text: records && records.preDayRetains ? records.preDayRetains : 0
            },
            {
              id: 6,
              name: '昨日预收款总金额',
              text:
                records && records.preDayTotalPreAmount
                  ? (records.preDayTotalPreAmount / 10000).toFixed(2)
                  : 0,
              work: '/万元'
            }
          ]
        },
        {
          name: '宴会累计数据',
          children: [
            {
              id: 1,
              name: '累计订单',
              text: records && records.totalOrders ? records.totalOrders : 0
            },
            {
              id: 2,
              name: '累计客户',
              text:
                records && records.totalCustomers ? records.totalCustomers : 0
            },
            {
              id: 3,
              name: '累计预收款',
              text:
                records && records.totalPreAmount
                  ? (records.totalPreAmount / 10000).toFixed(2)
                  : 0,
              work: '/万元'
            }
          ]
        }
      ]
    }
  },
  mounted() {
    const accountType = JSON.parse(window.localStorage.getItem('accountType'))
    // console.log('accountType:' + JSON.stringify(accountType))
    const type = []
    if (accountType) {
      if (accountType.banquet === 1) {
        type.push('banquet')
      }
      if (accountType.dinner === 1) {
        type.push('dinner')
      }
      if (accountType.meeting === 1) {
        type.push('meeting')
      }
    }
    this.type = type
    this.initDate()
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.generalize-page {
  width: 100%;
  height: 100%;
}
.box {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  font-size: 18px;
}
.real-time-data0 {
  width: 56%;
  height: 50%;
  .list {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
  }
  .item-list {
    width: 30%;
    text-align: center;
    margin: 0;
    padding: 24px 0;
  }
}
.real-time-data1 {
  width: 43%;
  height: 50%;
  .list {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
  }
  .item-list {
    width: 40%;
    text-align: center;
    margin: 0;
    padding: 24px 0;
  }
}
.common-functions {
  width: 100%;
  height: 46%;
}
</style>
