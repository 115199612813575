<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import { initMenu } from '@/utils/menus'
// import store from '@/store'
// import router from '@/router'

export default {
  name: 'App',
  data() {
    return {}
  },
  created() {
    const setHotelList = JSON.parse(window.localStorage.getItem('orgs'))
    if (setHotelList) {
      this.$store.commit('setHotelList', setHotelList)
    }
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem('store'))
        )
      )
      // if (this.$store.state.routes.length === 0) {
      //   initMenu(router, store)
      // }
    }

    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },
  destroyed() {
    this.$store.commit('clearVUEX')
  }
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;
}
</style>
