import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import Login from '../views/login/Login'
import Error404 from '../views/error/404'
import Home from '@/views/Home'
import { initMenu } from '@/utils/menus'
import store from '@/store'

Vue.use(VueRouter)
NProgress.configure({ showSpinner: false })

const routes = [
  {
    path: '/',
    name: '登录',
    component: Login,
    hidden: true
  },
  {
    path: '/login',
    name: '登录',
    component: Login,
    hidden: true
  },
  {
    path: '/home',
    name: '主页',
    component: Home,
    hidden: true
  }
]

// const requireContext = require.context(
//   './',
//   true,
//   /\.js$/
// )
// requireContext.keys().forEach(filename => {
//   if (filename !== './socket.js') {
//     const routeFile = requireContext(filename)
//     routes = [...routes, ...(routeFile.default || routeFile)]
//   }
// })
const router = new VueRouter({
  routes
})
// console.log('router', router.options.routes)
router.addRoute({
  path: '/404',
  name: '404',
  component: Error404
})

router.beforeEach((to, form, next) => {
  if (window.localStorage.getItem('expireDate')) {
    const expirDate = window.localStorage.getItem('expireDate')
    if (Date.now() > expirDate) {
      // 重置vuex中的数据
      store.commit('clearVUEX')
      const loginInfo = window.localStorage.getItem('loginInfo')
      window.localStorage.clear()
      window.localStorage.setItem('loginInfo', loginInfo)
      return next('/')
    }
  } else {
    window.localStorage.setItem('expireDate', Date.now() + 1000 * 60 * 60 * 6)
  }
  NProgress.start()
  if (to.path !== '/' && !localStorage.token) {
    return next('/')
  }
  if (to.path === '/' && localStorage.token) {
    return next('/home')
  }
  if (to.path !== '/') {
    // if(store.state.routes == undefined){
    //     return next('/login')
    // }
    if (!store.state.routes|| store.state.routes.length === 0) {
      initMenu(router, store)
    }
  }
  next()
})

router.afterEach((to, form) => {
  NProgress.done()
})

export default router
