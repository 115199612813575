// import Router from 'vue-router'
import { getRequest } from './api'
import { Message } from 'element-ui'
import router from '../router'
// import Vue from 'vue'
// Vue.use(Router)
export const initMenu = async(router, store) => {
  if (store.state.routes && store.state.routes.length > 0) {
    return false
  }
  const data = {
    userId: window.localStorage.getItem('bizId')
  }
  await getRequest('sys/permission/getMenuList', data).then(res => {
    // console.log('查询菜单结果：' + JSON.stringify(res))
    if (res.code !== 200) {
      if (res.code === 401) {
        Message.error(res.msg)
        router.push('/')
      }
      return false
    }
    if (res.data != undefined && res.data != null && res.data.length > 0) {
      const ress = res.data
      // 格式化好的数据
      const fmtRoutes = formatRoutes(null, ress)
      // 添加到路由
      // router.matcher = new Router().matcher
      if (fmtRoutes && fmtRoutes.length > 0) {
        fmtRoutes.forEach(i => {
          if (i.path && i.path !== null) {
            router.addRoute(i)
          }
        })
      }
      // 将数据存入vuex
      store.commit('initRoutes', fmtRoutes)
    }
  })
}

export const formatRoutes = (parent, routes) => {
  if (routes && routes.length > 0) {
    const fmtRoutes = []
    routes.forEach(o => {
      let { path, title, id, icon, children, component, hidden, parentId } = o
      if (children && children instanceof Array) {
        // 递归
        children = formatRoutes(o, children)
      }
      const fmRouter = {
        bizId: id,
        path: path,
        hidden: hidden,
        name: title,
        iconCls: icon,
        children: children,
        parentBizId: parentId,
        component(resolve) {
          if (path.startsWith('/home')) {
            require(['../views/Home.vue'], resolve)
          } else if (path === '/screen/big-screen') {
            require(['../views/' + component + '.vue'], resolve)
          } else {
            require(['../views/' + parent.component + '/' + component + '.vue'], resolve)
          }
        }
      }
      fmtRoutes.push(fmRouter)
    })
    // console.log('fmtRoutes:' + JSON.stringify(fmtRoutes))
    return fmtRoutes
  }

// export const formatRoutes = (parent, routes) => {
//   if (routes && routes.length > 0) {
//     const fmtRoutes = []
//     routes.forEach(o => {
//       let { url, label, bizId, iconClass, children, code, status, parentBizId } = o
//       if (children && children instanceof Array) {
//         // 递归
//         children = formatRoutes(o, children)
//       }
//       const fmRouter = {
//         bizId: bizId,
//         path: url,
//         status,
//         name: label,
//         iconCls: iconClass,
//         children: children,
//         parentBizId: parentBizId,
//         component(resolve) {
//           if (url.startsWith('/home')) {
//             require(['../views/Home.vue'], resolve)
//           } else if (url === '/screen/big-screen') {
//             require(['../views/' + code + '.vue'], resolve)
//           } else {
//             require(['../views/' + parent.code + '/' + code + '.vue'], resolve)
//           }
//         }
//       }
//       fmtRoutes.push(fmRouter)
//     })
//     return fmtRoutes
//   }
}
