<template>
  <div class="error">
    <span class="aaa">404</span>
    <br><br><br><br><br><br>
    <span class="bbb">页面找不到</span>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.error {
  text-align: center;
  margin-top: 50px;
  padding: 0;
  margin: 0;
  .aaa {
    font-size: 200px;
    color: #333;
    font-family: '方正舒体';
  }
  .bbb {
    font-size: 80px;
    color: #666;
    font-family: '方正舒体';
  }
}
</style>
