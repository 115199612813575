<template>
  <div class="page">
    <div class="login">
      <div class="left">
        <h2>鹿小乙预定管理软件</h2>
        <img src="../../assets/images/left.png" />
      </div>
      <div class="right">
        <!--        <i class="el-icon-full-screen screen"></i>-->
        <img width="80px" class="head" src="../../assets/images/logo.png" alt="" />
        <h2 class="title">登录账户</h2>
        <div class="inputbox">
          <i class="el-icon-s-custom"></i>
          <input v-model="loginForm.loginUsername" type="text" placeholder="请输入用户名" />
        </div>
        <div class="inputbox">
          <i class="el-icon-phone"></i>
          <input type="password" v-model="loginForm.password" placeholder="输入密码" />
        </div>
        <el-checkbox class="savepwd" v-model="loginForm.checked">记住用户名</el-checkbox>
        <el-button @click="fnLogin" class="loginbtn">登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { initMenu } from '@/utils/menus'
import store from '@/store'
import router from '@/router'

export default {
  data() {
    return {
      loginForm: {
        password: '', // 手机号
        loginUsername: '', // 登录用户名
        verificationCode: '', // 验证码
        checked: false
      },
      btnText: '发送验证码',
      disabled: false
    }
  },
  mounted() {
    if (window.localStorage.getItem('loginInfo')) {
      const loginInfo = JSON.parse(window.localStorage.getItem('loginInfo'))
      if (loginInfo) {
        this.loginForm = {
          ...loginInfo
        }
        if (loginInfo.verificationCode) {
          loginInfo.verificationCode = ''
        }
      }
    }
  },
  methods: {
    // 登录
    fnLogin() {
      if (
        this.loginForm.loginUsername == undefined ||
        this.loginForm.loginUsername == null ||
        this.loginForm.loginUsername == ''
      ) {
        this.$notify({
          title: '登录失败',
          message: '用户名不能为空',
          type: 'error'
        })
        return
      }
      // 判断手机号
      // const reg = /^1[3|4|6|5|7|8|9][0-9]\d{8}$/
      // if (!reg.test(this.loginForm.password)) {
      //   this.$notify({
      //     title: '登录失败',
      //     message: '手机号格式不正确',
      //     type: 'error'
      //   })
      //   return
      // }
      if (
        this.loginForm.password == undefined ||
        this.loginForm.password == null ||
        this.loginForm.password == ''
      ) {
        this.$notify({
          title: '登录失败',
          message: '请输入密码',
          type: 'error'
        })
        return
      }

      // if (
      //   this.loginForm.verificationCode == undefined ||
      //   this.loginForm.verificationCode == null ||
      //   this.loginForm.verificationCode == ''
      // ) {
      //   this.$notify({
      //     title: '登录失败',
      //     message: '填写验证码',
      //     type: 'error'
      //   })
      //   return
      // }

      this.postRequest(
        'sys/user/login',
        this.qs.stringify({
          username:
            this.loginForm.loginUsername + '&#' + this.loginForm.password,
          password: this.loginForm.password,
          saveLogin: 1
        })
      ).then(res => {
        console.log('token查询：' + JSON.stringify(res))
        if (res.code == 200) {
          // 1. 保存token
          window.localStorage.setItem('token', res.result)
          // 2. 判断验证码
          this.getRequest('sys/user/getCurrentUserInfo', {
            validate: 'undefined'
          }).then(response => {
            // console.log('登录结果：' + JSON.stringify(response))
            if (response.code == 200) {
              // 3. 保存当前用户id
              window.localStorage.setItem('bizId', response.data.id)
              // 4. 存储权限（判断酒店类型）
              window.localStorage.setItem(
                'accountType',
                response.data.accountType
              )
              console.log('accountType',
                response);
              // 6. 当前用户所有角色 roles
              if (response.data.roles) {
                window.localStorage.setItem(
                  'roles',
                  JSON.stringify(response.data.roles)
                )
              }
              // 7. 用户权限，验证点击事件
              window.localStorage.setItem('authority', response.data.authority)
              // 8. 存储酒店信息 --orgs 另外放一个list中 这两个list不能冲突
              if (response.data.orgs) {
                // 8.1 存储第一个元素。默认第一个酒店
                var arr = []
                arr.push(response.data.orgs[0])
                window.localStorage.setItem('orgs', JSON.stringify(arr))
                this.$store.commit('setHotelList', arr)
                // 5. 存储酒店标识：applicationName
                window.localStorage.setItem(
                  'applicationName',
                  response.data.orgs[0].applicationName
                )
                window.localStorage.setItem(
                  'hotelBizId',
                  response.data.orgs[0].id
                )
                // 8.2 保存所有酒店，用户切换酒店时使用
                window.localStorage.setItem(
                  'orgList',
                  JSON.stringify(response.data.orgs)
                )
                this.$store.commit('setHotels', response.data.orgs)
              }
              // 9. 保存当前用户基本信息
              const info = {
                name: response.data.name || '管理员',
                loginUsername: response.data.username || 'admin',
                phoneNumber: response.data.phone || '',
                hotelLogoUrl: response.data.logoUrl || '',
                photoUrl: response.data.photoUrl || ''
              }
              window.localStorage.setItem('info', JSON.stringify(info))
              //   console.log('orgs:' + JSON.stringify(arr))
              var defaultUrl = '/home'
              if (arr[0].state != undefined && arr[0].state != null) {
                if (arr[0].state == 4) {
                  defaultUrl = 'zeropoint/salescontrol-table'
                }
                if (arr[0].state == 5) {
                  defaultUrl = '/banquet/salescontrol-table'
                }
              }
              initMenu(router, store).then(resultmenu => {
                // 10. 跳转首页
                const path = this.$route.query.redirect

                setTimeout(() => {
                  this.$router.replace(
                    path === '/' || path === undefined ? defaultUrl : path
                  )
                }, 200)
              })
            } else {
              this.$message.error(response.msg)
              return false
            }
          })
        } else {
          return false
        }
      })
    },

    fnLogin_next() {
      this.postRequest(
        'sys/user/login',
        this.qs.stringify({
          username:
            this.loginForm.loginUsername + '&#' + this.loginForm.password,
          password: this.loginForm.password,
          saveLogin: 1
        })
      ).then(res => {
        // console.log('token查询：' + JSON.stringify(res))
        if (res.code == 200) {
          // 1. 保存token
          window.localStorage.setItem('token', res.result)
          // 2. 判断验证码
          this.getRequest('sys/user/getCurrentUserInfo', {
            validate: this.loginForm.verificationCode
          }).then(response => {
            // console.log('登录结果：' + JSON.stringify(response))
            if (response.code == 200) {
              // 3. 保存当前用户id
              window.localStorage.setItem('bizId', response.data.id)
              // 4. 存储权限（判断酒店类型）
              window.localStorage.setItem(
                'accountType',
                response.data.accountType
              )
              console.log('accountType',
                response);
              // 6. 当前用户所有角色 roles
              if (response.data.roles) {
                window.localStorage.setItem(
                  'roles',
                  JSON.stringify(response.data.roles)
                )
              }
              // 7. 用户权限，验证点击事件
              window.localStorage.setItem('authority', response.data.authority)
              // 8. 存储酒店信息 --orgs 另外放一个list中 这两个list不能冲突
              if (response.data.orgs) {
                // 8.1 存储第一个元素。默认第一个酒店
                var arr = []
                arr.push(response.data.orgs[0])
                window.localStorage.setItem('orgs', JSON.stringify(arr))
                this.$store.commit('setHotelList', arr)
                // 5. 存储酒店标识：applicationName
                window.localStorage.setItem(
                  'applicationName',
                  response.data.orgs[0].applicationName
                )
                window.localStorage.setItem(
                  'hotelBizId',
                  response.data.orgs[0].id
                )
                // 8.2 保存所有酒店，用户切换酒店时使用
                window.localStorage.setItem(
                  'orgList',
                  JSON.stringify(response.data.orgs)
                )
                this.$store.commit('setHotels', response.data.orgs)
              }
              // 9. 保存当前用户基本信息
              const info = {
                name: response.data.name || '管理员',
                loginUsername: response.data.username || 'admin',
                phoneNumber: response.data.phone || '',
                hotelLogoUrl: response.data.logoUrl || '',
                photoUrl: response.data.photoUrl || ''
              }
              window.localStorage.setItem('info', JSON.stringify(info))
              //   console.log('orgs:' + JSON.stringify(arr))
              var defaultUrl = '/home'
              if (arr[0].state != undefined && arr[0].state != null) {
                if (arr[0].state == 4) {
                  defaultUrl = 'zeropoint/salescontrol-table'
                }
                if (arr[0].state == 5) {
                  defaultUrl = '/banquet/salescontrol-table'
                }
              }
              initMenu(router, store).then(resultmenu => {
                // 10. 跳转首页
                const path = this.$route.query.redirect

                setTimeout(() => {
                  this.$router.replace(
                    path === '/' || path === undefined ? defaultUrl : path
                  )
                }, 200)
              })
            } else {
              this.$message.error(response.msg)
              return false
            }
          })
        } else {
          return false
        }
      })
    },
    // 发送验证码
    bindforgetSendCode() {
      // 手机号 为空的话
      if (this.loginForm.phoneNumber !== '') {
        this.post('message/verificationCode', {
          phone: this.loginForm.phoneNumber,
          username: this.loginForm.loginUsername
        }).then(res => {
          if (res.code == 200) {
            this.$message('验证码已发送，请注意查收')
            this.disabled = true
            this.btnText = '请稍候...'
            setTimeout(() => {
              this.doLoop(60)
            }, 500)
          } else {
            this.$message(res.msg)
            return false
          }
        })
      } else {
        this.$message('手机号不能为空')
        return false
      }
    },
    // 手机验证码的倒计时
    doLoop: function(seconds) {
      seconds = seconds || 180
      this.btnText = '倒计时' + seconds + '秒'
      const countdown = setInterval(() => {
        if (seconds > 0) {
          this.btnText = '倒计时' + seconds + '秒'
          --seconds
        } else {
          this.btnText = '获取验证码'
          this.disabled = false
          clearInterval(countdown)
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 100vh;
  background: url('../../assets/images/bj.png');
  background-position: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  .login {
    width: 1200px;
    height: 600px;
    background: rgba(255, 255, 255, 1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    display: flex;
    .left {
      width: 50%;
      height: 100%;
      padding: 5% 0;
      h2 {
        line-height: 60px;
        text-align: center;
        margin: 0;
        font-size: 45px;
      }
      img {
        width: 100%;
      }
    }
    .right {
      box-sizing: border-box;
      width: 50%;
      height: 100%;
      padding: 5% 10%;
      font-size: 16px;
      position: relative;
      .screen {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 50px;
        color: #ccc;
      }
      .head {
        display: block;
        margin: 0 auto;
      }
      .title {
        line-height: 40px;
        text-align: center;
        font-size: 30px;
        margin: 10px;
      }
      .inputbox {
        padding-right: 10px;
        height: 60px;
        line-height: 70px;
        border-bottom: 1px solid #ccc;
        margin: 0 auto;
        position: relative;
        i {
          padding: 0 5px;
          color: #999;
        }
        input {
          border: 0;
          padding-left: 10px;
          color: #666;
        }
        span {
          float: right;
          color: #ccc;
        }
        .bind_code_gain {
          position: absolute;
          right: 2px;
          box-shadow: 1px 1px 4px #ccc;
          border: 0;
          font-size: 13px;
          color: #15d37d;
          cursor: pointer;
          bottom: 5px;
          padding: 10px;
        }
      }
      .savepwd {
        display: block;
        margin: 20px 0;
      }
      .loginbtn {
        display: block;
        width: 70%;
        font-size: 24px;
        margin: 50px auto;
        background: #ffca00;
        color: #000;
      }
    }
  }
}
</style>
