<template>
  <div class="menu-page">
    <el-menu class="menu" router :collapse="collapse" :default-openeds="['0']" :background-color="backgroundColor" text-color="#fff" unique-opened>
      <div v-for="(item, index) in router" :key="index">
        <span v-if="itemconvert(item)"></span>
        <el-submenu :index="index + ''" v-if="item.hidden" v-show="item.name != '应用' && item.name != '设置' && item.name != '短信'">
          <template slot="title">
            <i :class="'iconfont ' + item.iconCls"></i>
            <span @click="onMenuClick(item)">{{ item.name }}</span>
          </template>
          <template v-if="item.children !== undefined && item.children !== null">
            <div v-for="(children, indexj) in item.children" :key="indexj">
              <div style="color: #fff; padding: 15px; font-size: 14px; cursor: pointer" :index="children.path" v-if="children.hidden" @click="onMenuChildrenClick(children, item)">
                {{ children.name }}
              </div>
            </div>
          </template>
        </el-submenu>
      </div>
    </el-menu>
    <div class="btngrounp">
      <el-button v-if="list.includes('应用')" @click="onApplicationRouter" type="text" class="btn">
        <i class="icon el-icon-menu"></i>
        应用
      </el-button>
      <el-button v-if="list.includes('设置')" @click="onSettingRouter" type="text" class="btn">
        <i class="icon el-icon-s-tools"></i>
        设置
      </el-button>
    </div>
  </div>
</template>

<script>
let authority = null
export default {
  name: 'MenuVue',
  data() {
    return {
      collapse: false,
      backgroundColor: '#444',
      list: [],
      router: []
    }
  },
  computed: {
    routes() {
      // console.log('当前路由：' + JSON.stringify(this.$store.state.routes))
      return this.$store.state.routes
    },
    org() {
      return window.localStorage.getItem('orgs')
    }
  },
  watch: {
    routes() {
      this.init()
    }
  },
  mounted() {
    const accountType = JSON.parse(window.localStorage.getItem('accountType'))
    const type = []
    if (accountType) {
      if (accountType.banquet === 1) {
        type.push('banquet')
        this.collapse = true
      }
      if (accountType.dinner === 1) {
        type.push('dinner')
        this.collapse = false
      }
      if (accountType.meeting === 1) {
        type.push('meeting')
        this.collapse = true
      }
    }
    // this.collapse = type.includes('banquet')

    this.init()
  },
  methods: {
    itemconvert(item) {
      //   console.log('项目：' + JSON.stringify(item))
      return true
    },
    init() {
      this.router = this.routes
      const org2 = JSON.parse(this.org)
      console.log(org2);
      if (org2[0].code === 'luxiaoyiyunyinghoutai') {
        const arr = this.router.filter(item => {
          if (item.name != '宴会' && item.name != '零点' && item.name != '数据' && item.name != '财务' && item.name != '应用' && item.name != '设置') {
            return true
          }
        })
        this.router = arr
      }
      if (this.router && this.router.length > 0) {
        this.router.forEach(o => {
          if (o.name === '应用' || o.name === '设置') {
            this.list.push(o.name)
          }
        })

        if (window.localStorage.getItem('authority')) {
          authority = JSON.parse(window.localStorage.getItem('authority'))
        } else {
          authority = null
        }
      }
    },
    onApplicationRouter() {
      if (authority && authority.applicationMenu === 1) {
        const menu = {
          path: '/application',
          name: '全局应用',
          bizId: '/application'
        }
        this.selectMenu(menu)
        // this.$router.push('/application')
      } else {
        this.$message({
          duration: 10000,
          message: '您暂无操作权限，请联系酒店管理员'
        })
      }
    },
    onSettingRouter() {
      if (authority && authority.settingMenu === 1) {
        const menu = {
          path: '/setup',
          name: '全局设置',
          bizId: '/setup'
        }
        this.selectMenu(menu)
      } else {
        this.$message({
          duration: 10000,
          message: '您暂无操作权限，请联系酒店管理员'
        })
      }
    },
    onMenuClick(menu) {
      if (!menu.children || !(menu.children.length > 0)) {
        this.selectMenu(menu)
      }
    },
    onMenuChildrenClick(menu, children) {
      this.selectMenu(menu)
      // switch (children.name) {
      //   case '宴会':
      //     if (authority && authority.banquetMenu === 1) {
      //       this.selectMenu(menu)
      //     } else {
      //       this.$message({
      //         duration: 10000,
      //         message: '您暂无操作权限，请联系酒店管理员'
      //       })
      //     }
      //     break
      //   case '零点':
      //     if (authority && authority.dinnerMenu === 1) {
      //       this.selectMenu(menu)
      //     } else {
      //       this.$message({
      //         duration: 10000,
      //         message: '您暂无操作权限，请联系酒店管理员'
      //       })
      //     }
      //     break
      //   case '会议':
      //     if (authority && authority.meetingMenu === 1) {
      //       this.selectMenu(menu)
      //     } else {
      //       this.$message({
      //         duration: 10000,
      //         message: '您暂无操作权限，请联系酒店管理员'
      //       })
      //     }
      //     break
      //   case '数据':
      //     if (authority && authority.dataMenu === 1) {
      //       this.selectMenu(menu)
      //     } else {
      //       this.$message({
      //         duration: 10000,
      //         message: '您暂无操作权限，请联系酒店管理员'
      //       })
      //     }
      //     break
      //   case '财务':
      //     if (authority && authority.financeMenu === 1) {
      //       this.selectMenu(menu)
      //     } else {
      //       this.$message({
      //         duration: 10000,
      //         message: '您暂无操作权限，请联系酒店管理员'
      //       })
      //     }
      //     break
      //   default:
      //     this.selectMenu(menu)
      //     break
      // }
    },
    // 点击菜单 - 传入name，添加到keepalive缓存页面
    selectMenu(item) {
      // 历史选中菜单
      const oldActivePath = this.$store.state.activePath
      // 首先判断点击的是否是自己，如果是自己则return
      if (oldActivePath === item.bizId) {
        return
      }
      // 加入keepalive缓存
      this.$store.commit('addKeepAliveCache', item.name)
      // 添加tags标签
      const submenu = {
        path: item.path,
        name: item.name,
        bizId: item.path
      }
      // 修改选中菜单
      this.$store.commit('selectMenu', submenu)
      this.$router.push(submenu.path)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/css/public';
a {
  text-decoration: none;
  color: #fff;
}
.menu-page {
  position: relative;
  width: 101%;
  height: 100%;
  background-color: $theme-bg-color;
  color: #fff;
  .menu:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
  .menu {
    height: auto;
    width: 100%;
    padding: 0;
    .iconfont {
      color: #fff;
      margin-right: 5px;
      margin-left: -10px;
      font-size: 18px;
    }
    .menu-one {
      padding: 15px 0;
      font-size: 14px;
      padding-left: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .iconfont {
      font-size: 20px;
    }
    .menu-one:hover {
      background: #333333;
    }
  }
}
.btngrounp {
  position: absolute;
  bottom: 100px;
  z-index: 2;
  width: 100%;
  align-items: center;
  .btn {
    width: 100%;
    padding: 15px 0;
    color: #ffffff;
    box-sizing: border-box;
    font-size: 14px;
    padding-left: 8px;
    margin: 0;
    text-align: left;
    .icon {
      font-size: 18px;
      position: relative;
      top: 2px;
    }
  }
  .btn:hover {
    background: #333333;
  }
}
</style>
