import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import store from './store'
import VueStomp from 'vue-stomp'

import 'normalize.css/normalize.css'
// 引入公共样式
import '@/assets/css/index.css'
import '@/assets/element-fonts/index.css'
// 引入font图标
import './assets/font/iconfont.css'
import qs from 'qs'
import {
  postRequest,
  getRequest,
  getRequestImg,
  putRequest,
  deleteRequest,
  //   postRequestAdmin,
  //   getRequestAdmin,
  //   getRequestShort,
  //    postRequestShort,
  postRequestImage,
  post
} from './utils/api'
import print from '@/utils/print'
import { parseTime, formatDate } from './utils/date'
import VueDND from 'awe-dnd'

import * as echarts from 'echarts'
// 图片放大
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

import Clipboard from 'clipboard'

// 图片选择
import vueSelectTu from 'vue-select-tu'
Vue.use(vueSelectTu)
Vue.prototype.Clipboard = Clipboard
Vue.prototype.$echarts = echarts

Vue.use(print)
Vue.use(VueStomp)
// Vue.use(Viewer)
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})

Vue.use(VueDND)

// 插件形式使用请求
// 配全局属性配置，在任意组件内可以使用this.$qs获取qs对象
Vue.prototype.qs = qs
Vue.prototype.postRequest = postRequest
// Vue.prototype.postRequestAdmin = postRequestAdmin
// Vue.prototype.getRequestShort = getRequestShort
// Vue.prototype.postRequestShort = postRequestShort
Vue.prototype.getRequest = getRequest
Vue.prototype.getRequestImg = getRequestImg
// Vue.prototype.getRequestAdmin = getRequestAdmin
Vue.prototype.putRequest = putRequest
Vue.prototype.deleteRequest = deleteRequest
Vue.prototype.postRequestImage = postRequestImage
Vue.prototype.post = post
Vue.prototype.parseTime = parseTime
Vue.prototype.formatDate = formatDate

Vue.config.productionTip = false

Vue.use(ElementUI, { size: 'small' })
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
